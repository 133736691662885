/* @import "~normalize.css/normalize.css"; */
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Recursive&display=swap");

:root {
  --startColor: hsl(0, 100%, 75%);
  --stopColor: hsl(0, 100%, 75%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overscroll-behavior-y: none;
  font-family: "Space Mono", monospace;
  /* border: 1px solid #f00 !important; */
  /* max-width: 100%; */
}
html {
  width: 100%;
  max-width: 100%;
}
body {
  font-family: "Space Mono", monospace;
  width: 100%;
  max-width: 100%;
  background: rgb(9, 9, 1);
  /* overflow-x: hidden; */
}
main {
  /* overflow-x: hidden; */
  /* min-height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  height: 300vh;
  width: 100%;
}
section {
  /* overflow: hidden; */
}
.scroll-container {
  width: 100%;
  position: relative;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

/* TEST  */
.shape-overlays {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 999999;
}
.shape-overlays__path:nth-of-type(3) {
  /* fill: blueviolet; */
  z-index: 9999999;
}
.shape-overlays__path:nth-of-type(2) {
  /* fill: yellow; */
  z-index: 9999999;
}
.shape-overlays__path:nth-of-type(1) {
  /* fill: red; */
  z-index: 9999999;
}
.fluid-menu {
  left: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8vw;
  opacity: 0;
}
.fluid-menu ul {
  /* opacity: 0; */
  pointer-events: none;
  z-index: 99999999;
  text-decoration: none;
  color: white;
  transform: translateY(-100vh);
}

.fluid-menu a {
  /* text-align: center; */
  text-transform: uppercase;
  font-family: "Recursive", sans-serif;
}
.transitioning {
  pointer-events: none;
}
/* .current-link {
  pointer-events: none;
  text-decoration: line-through;
} */
.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0 1rem;
  color: white;
  font-size: 3rem;
  line-height: 1.2;
  z-index: 999999999;
  cursor: pointer;
}
.contact-section {
  height: 100vh;
  overflow: hidden;
}
a {
  transition: color 0.2s;
}
a:link,
a:visited {
  text-decoration: none;
  color: white;
}
/* mouse over link */
a:hover {
  transition: color 0.2s;
  color: hotpink;
}
ul {
  list-style: none;
}
#logo {
  position: fixed;
  margin: 1em;
  width: 28px;
  height: 27px;
  /* mix-blend-mode: difference; */
  z-index: 999999999;
}
p {
  font-family: "Space Mono", monospace;
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
* SVG BLOB ANIMATION
*/
.svg-container {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(310vh + 150px);
  display: grid;
  place-items: center;
  pointer-events: none;
}
svg {
  height: 100%;
  width: 100%;
}
#blobs {
  position: absolute;
}
#title-text {
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.text-container h1 {
  /* font-size: 11vw; */
  font-size: 55px;
  color: white;
  text-align: center;
}
#path-ffl {
  animation: 20s moveffl infinite linear alternate;
  fill: rgb(107, 225, 255);
  animation-delay: -4s;
}
#path-fl {
  animation: 25.5s move1 infinite linear alternate-reverse;
  fill: rgb(255, 123, 0);
  animation-delay: -8.5s;
}
#path-fr {
  /* fill: red; */
  animation: 17s move2 infinite linear alternate-reverse;
  fill: rgb(255, 22, 159);
  animation-delay: -6s;
}
#path-c {
  animation: 26s move3 infinite linear alternate-reverse;
  /* fill: hsl(12, 100%, 50%); */
  /* fill: rgb(255, 51, 0); */
}
#path4 {
  animation: 19s move4 infinite linear alternate-reverse;
  /* fill: purple; */
}
#path-r {
  animation: 16s move5 infinite linear alternate-reverse;
  fill: rgb(209, 0, 209);
}

@keyframes moveffl {
  0% {
    transform: translate(4%, 0vh);
  }
  100% {
    transform: translate(4%, 300vh);
  }
}
@keyframes move1 {
  0% {
    transform: translate(61%, 0vh);
  }
  100% {
    transform: translate(61%, 150vh);
  }
}
@keyframes move2 {
  0% {
    transform: translate(55%, 0vh);
  }
  100% {
    transform: translate(55%, 210vh);
  }
}
@keyframes move3 {
  0% {
    transform: translate(20%, 100vh);
  }
  100% {
    transform: translate(20%, 0vh);
  }
}
@keyframes move4 {
  0% {
    transform: translate(40%, 0vh);
  }
  100% {
    transform: translate(40%, 100vh);
  }
}
@keyframes move5 {
  0% {
    transform: translate(50%, 300vh);
  }
  100% {
    transform: translate(50%, -10vh);
  }
}
@keyframes moveBorder {
  0% {
    transform: translate(44%, 293vh);
  }
  100% {
    transform: translate(44%, 274vh);
  }
}
@keyframes moveCandy {
  0% {
    transform: translate(75%, 210vh) scale(1.5);
  }
  100% {
    transform: translate(75%, 290vh) scale(1.5);
  }
}

#path-border {
  transform: translate(44%, 250vh) scale(0.5);
  /* fill: rgba(255, 0, 34, 0.04); */
  animation: 9s moveBorder infinite linear alternate-reverse;
  fill: rgb(113, 0, 189);
  /* pointer-events: all; */
}
#path-candy {
  transform: translate(75%, 210vh) scale(1.5);
  animation: 22s moveCandy infinite linear alternate-reverse;
  /* pointer-events: all; */
}

/*
* INFO SECTION
*/
.info-section {
  width: 100%;
  height: 100vh;
  position: relative;
}
.info-text-container {
  position: absolute;
  right: 0;
  height: 90%;
  display: flex;
  flex-direction: column;

  /* THIS SHOULD SWAP BETWEEN SPACE_AROUND AND SPACE_BETWEEN IN MEDIA QUERIES  */
  justify-content: center;
  margin: 2rem 3rem 0 2rem;
}
.info-text {
  font-size: 6vw;
  color: white;
  text-align: right;
  overflow: hidden;
  opacity: 0;
}

.cta-button {
  background: none;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1.1em;
  margin: 2.5em 2.7em;
  border-radius: 50px;
  outline: none;
  border: white 2px solid;
  cursor: pointer;
  /* font-family: "Lexend", sans-serif; */
  font-family: "Recursive", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.cta-button-card-section {
  position: relative;
  color: rgb(255, 255, 255);
  background: rgb(255, 0, 157);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.363);
  letter-spacing: 0.1em;
  margin: 0;
  border: none;
}
.splitting .char {
  display: inline-block;
}
/* .splitting .char {
  animation: slide-in 1s cubic-bezier(0.5, 0, 0.5, 1) both;
  animation-delay: calc(60ms * var(--char-index));
}

@keyframes slide-in {
  from {
    transform: translateY(-1em) rotate(-0.5turn) scale(0.5);
    opacity: 0;
  }
} */
/*
* CARD SECTION
*/
.card-section-container {
  height: 100vh;
  width: 100%;
  position: relative;
}
.card-section-title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.7em 4rem;
  color: white;
  font-size: 3.2vw;
}
/* .card-section {
  max-width: 1400px;
  margin: 0 auto;
} */
.card-section,
.card-section-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  /* overflow: hidden; */
}
.card-section-bg {
  position: absolute;
}
.glass-card,
.glass-card-bg {
  text-align: center;
  flex: 1;
  margin: 1em;
  height: 60vh;
  border-radius: 10px;
}
.glass-card svg {
  /* margin-top: 3em; */
}
.glass-card-bg {
  background: rgba(0, 0, 0, 0.3);
  border: rgba(255, 115, 248, 0.1) 1px solid;
}
.glass-card {
  backdrop-filter: blur(15px);
  border: rgba(255, 255, 255, 0.06) 1px solid;
  padding: 2em;
  color: white;
}
.glass-card h3 {
  font-size: 4vw;
  margin-bottom: 1rem;
}
.card-section-bottom {
  color: white;
  width: 100%;
  /* height: 6em; */
  margin-top: -6em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.card-section-bottom p {
  font-size: 2vw;
}

/*
* PORTFOLIO SECTION
*/

.portfolio-section {
  /* position: absolute; */
  /* top: 210vh; */
  /* left: 0; */
  width: 100%;
  height: calc(100vh + 100px);
  overflow: hidden;
  background: linear-gradient(#e6646400, #bc91e52f, #ec1fff4f);
  /* clip-path: url(#myClip); */
}
.portfolio-section-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
}
.portfolio-section-content {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}
.portfolio-title {
  margin: 5vw 4rem 0;
  color: white;
  font-size: 3.6vw;
  justify-self: flex-start;
  align-self: start;
  z-index: 9;
}
/* change this  */
/* .svg-particles {
  position: absolute;
  top: -20vh;
  width: 100vw;
  height: 140vh;
  background: none;
} */
.svgtext--1 {
  font-size: 66px;
  color: white;
  width: 100%;
  height: 100vh;
  fill: white;
  z-index: 20;
  pointer-events: none;
}
.svgtext--1 textPath {
  cursor: pointer;
  pointer-events: all;
}
.svgtext--2 {
  position: absolute;
  top: 14vh;
  font-size: 24px;
  color: white;
  width: 100%;
  height: 100vh;
  fill: white;
  z-index: 20;
  pointer-events: none;
}
#clip-path {
  transform: translate(144px, 150px) scale(1.6);
}
.clip-border {
  background-color: rgb(212, 212, 212);
  /* clip-path: url(#clip-path); */
  clip-path: circle(200px at center);
  height: 500px;
  width: 500px;
  position: absolute;
  transform-origin: center;
  transform: scale(1.003);
  transition: transform 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.masked-image {
  position: absolute;
  clip-path: circle(200px at center);
  /* clip-path: url(#clip-path); */
  height: 500px;
  width: 500px;
  transition: transform 0.7s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 1.2s;
}
.masked-image-0 {
  background: url("./imgs/richback.png") center center / 100% no-repeat;
  opacity: 0;
}
.masked-image-1 {
  background: url("./imgs/costEarth.png") center center / 100% no-repeat;
  opacity: 1;
}
.masked-image-2 {
  /* background: url("./imgs/orange.jpeg") center / 100% no-repeat; */
}
.masked-image-small-0 {
  background: url("./imgs/3dECC.png") center / 100% no-repeat;
}
.masked-image-small-1 {
  background: url("./imgs/costEarthStats.png") center / 100% no-repeat;
}

.blob-image {
  /* position: absolute; */
  position: relative;
}
.blob-image-1 {
  transform: translateY(-80px);
  flex: 1 60%;
  /* top: 200px; */
  /* left: 0px; */
}
.blob-1-text {
  font-size: 3vw;
  flex: 1 40%;
}
.blob-image-2 {
  left: 35vw;
  bottom: 300px;
  transform: scale(0.6);
  /* transform: translate(300px, -220px) scale(0.6); */
}

.arrow-next {
  /* font-size: 8vw; */
  width: 8vw;
  height: 8vw;
  stroke: white;
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2rem;
  cursor: pointer;
  z-index: 99999;
}
.arrow-next path {
  animation: pulsate-back 2s ease-in-out infinite both;
}
@keyframes pulsate-back {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.9);
  }
  100% {
    transform: scaleX(1);
  }
}
/*
* END PORTFOLIO SECTION
*/

footer {
  background: rgb(51, 0, 85);
  /* background: url(#gradient); */
  width: 100%;
  min-height: 250px;
  color: white;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow: hidden; */
}
.custom-shape-divider-bottom-1617084803 {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1617084803 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-bottom-1617084803 .shape-fill {
  fill: rgb(51, 0, 85);
  /* fill: ; */
}
.footer-main {
  display: flex;
  justify-content: space-evenly;
  overflow: hidden;
}
.footer-section {
  margin-top: 1em;
  height: 150px;
  width: 100%;
}
.footer-section h5 {
  font-family: "Recursive", sans-serif;
  text-transform: uppercase;
  font-size: 1.2em;
  margin: 0.5em 0 1em 0;
}
.footer-section li {
  font-size: 1em;
  margin: 1em;
}
.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
  font-family: "Recursive", sans-serif;
  font-size: 0.8rem;
}

@media screen and (max-width: 680px) {
  .custom-shape-divider-bottom-1617084803 {
    top: -50px;
  }
  .custom-shape-divider-bottom-1617084803 svg {
    height: 50px;
  }
  .footer-section {
    margin-top: 1.5em;
    height: 180px;
  }
  .footer-section-contact {
    display: none;
  }
}

/*
* ABOUT PAGE
*/
.about-section {
  margin: 2em;
  max-width: 1300px;
  margin: 0 auto;
  padding: 2em;
}
.about-section h2 {
  color: white;
  font-size: clamp(2em, 6vw, 5em);
  margin: 0.4em;
}
.about-section h3 {
  /* color: white;
  font-size: clamp(1.2em, 2vw, 2.4em); */
  text-align: center;

  margin: 0.6em 0;
}
.about-section p {
  /* font-size: clamp(1em, 1.5vw, 2em); */
}
.about-section .first-line {
  margin: 0em auto 1em;
  text-align: center;

  max-width: 700px;
  /* margin: 0 auto; */
  text-align: center;
  text-shadow: 1px 1px rgb(43, 43, 43);
}
.about-section .last-line {
  margin: 1em 0;
  text-align: center;
  text-shadow: 1px 1px rgb(43, 43, 43);
}
.about-section .multi-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 1em 0;
}
.about-section .multi-container > div {
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid white;
  padding: 0 1em 2em;
  margin: 1em;
  flex: 1;
}

/*
* CARDS
*/
:root {
  --surface-color: #fff;
  --curve: 30;
}

* {
  box-sizing: border-box;
}

/* body {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
} */

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  gap: 2rem;
  margin: 4rem 0;
  padding: 0;
  list-style-type: none;
}
.cards li {
  border: 5px solid white;
  border-radius: 36px;
}

.card {
  min-width: 200px;
  max-width: 330px;
  flex-basis: auto; /* default value */
  flex-grow: 1;
  background: white;
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.card__thumb.crayon,
.card__thumb.lightning {
  border-radius: 0%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 0.3em;
  color: #6a515e;
}

/* .card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
} */

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #7a6e74;
  /* font-family: "MockFlowFont";    */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

/*
* CONTACT PAGE
*/

.contact-section {
  margin: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-content-container {
  max-width: 1200px;
}
.contact-section h2 {
  color: white;
  font-size: clamp(2rem, 6vw, 5rem);
  margin: 0em;
}
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid white;
  width: calc(100% - 3em);
  margin: 3.5em 1.5em 1.5em 1.5em;
  padding: 2.5vw 2vw 2vw 2vw;
  background: rgba(0, 0, 0, 0.2);
}
.contact-name-email {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 0em);
}
.contact-container input,
textarea {
  border: white 1px solid;
  background: none;
  color: white;
  padding: 0.5em 1em;
  border-radius: 15px;
}
#contact-name {
  /* justify-self: flex-start; */
  width: calc(100% - 0em);
  margin-right: 1em;
}
#contact-email {
  /* justify-self: flex-end; */
  width: calc(100% - 0em);
  margin-left: 1em;
}
#contact-message {
  margin: 1.5em 0;
  min-width: calc(100% - 0em);
  max-width: calc(100% - 0em);
  width: calc(100% - 0em);
  min-height: 30vh;
}
.contact-submit-button {
  border: white 1px solid;
  background: none;
  color: white;
  padding: 0.5em 1em;
  border-radius: 15px;
}
.contact-back {
  /* text-align: center; */
  margin: 3em 0em 0 0;
  font-size: 2em;
}
.contact-arrow {
  display: inline-block;
  transform: rotate(180deg);
}

/*
* Media Queries
*/

@media screen and (max-width: 780px) {
  /* .glass-card,
  .glass-card-bg {
    flex-basis: 40%;
  }
  .glass-card,
  .glass-card-bg:last-child {
    flex-basis: 100%;
  } */
}

@media screen and (max-width: 680px) {
  .glass-card,
  .glass-card-bg {
    flex-basis: 100%;
    height: 30vh;
    padding: 0;
    /* margin: 0 0 5px 0; */
  }
  .cta-button-card-section {
    /* display: none; */
    margin-top: 9em;
  }
  .portfolio-section {
    margin-top: 9em;
  }
  .portfolio-title {
    margin: 5vw 1rem 0;
    font-size: 7.6vw;
  }
  .contact-container {
    width: 100%;
    margin: 2em 0;
  }
  .svgtext--1 {
  }
  .svgtext--2 {
    font-size: 32px;
    top: 10vh;
  }
  .arrow-next {
    width: 16vw;
    height: 16vw;
  }
  .card-section-title {
    display: none;
  }
}
